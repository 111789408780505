<template>
    <el-dialog
            id="messagePush"
            title="消息推送"
            :visible.sync="messagePush"
            width="50%"
            :close-on-click-modal="false"
            :before-close="handleClose">
        <el-menu :default-active="ruleForm.tabIndex" @select="handleSelectTab" class="el-menu-demo" mode="horizontal">
            <el-menu-item index="0">服务号消息</el-menu-item>
            <el-menu-item index="1">H5消息</el-menu-item>
        </el-menu>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
            <el-row v-if="ruleForm.tabIndex == '0' && activity">
                <el-col :span="12" v-if="ruleForm.tabIndex == '0'">
                    <el-form-item label="消息类型" prop="leixing">
                        <el-select style="width: 100%" oninput="this.value=this.value.replace(/(^\s*)|(\s*$)/g,'')" size="small" v-model="ruleForm.leixing" placeholder="请选择类型">
                            <el-option
                                    v-for="item in optionsleixing"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="消息标题" prop="inputbiaoti">
                        <el-input size="small" oninput="this.value=this.value.replace(/(^\s*)|(\s*$)/g,'')" show-word-limit maxlength="20" v-model="ruleForm.inputbiaoti" placeholder="请输入标题"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-if="ruleForm.tabIndex == '0'" prop="textarea">
                <el-col :span="20">
                    <el-form-item label="活动消息"  prop="textarea">
                        <el-input
                                oninput="this.value=this.value.replace(/(^\s*)|(\s*$)/g,'')"
                                show-word-limit
                                maxlength="100"
                                size="small"
                                type="textarea"
                                :rows="2"
                                placeholder="请输入内容"
                                v-model="ruleForm.textarea">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="messagePush-anniu">
                <el-button @click="addActivityLabel(1)">取 消</el-button>
                <el-button type="primary" @click="addActivityLabel(0)">确 定</el-button>
            </div>
        </el-form>
    </el-dialog>
</template>

<script>
    import Editor from '@/components/editor/editor'; // 导入富文本上传组件
    export default {
        name: "messagePush",
        components: {Editor},
        props: {
            messagePush: Boolean,
            xiaoxi: Object,
            activity:Boolean,
        },
        data() {
            return {
                ruleForm: {
                    tanchuan: false,
                    tabIndex: '0',
                    leixing: '', //消息类型
                    inputbiaoti: '',//消息标题
                    textarea: '', //活动消息
                    actiContent: '',
                },
                rules: {
                    inputbiaoti: [{
                        required: true, message: '请输入标题', trigger: 'blur'
                    }],
                    textarea: [{
                        required: true, message: '请输入活动消息', trigger: 'blur'
                    }],
                },
                optionsleixing: [
                    {
                        value: 0,
                        label: '邀请消息'
                    },
                    {
                        value: 1,
                        label: '通知消息'
                    },
                    {
                        value: 2,
                        label: '全部'
                    },
                ],
            };
        },
        methods: {
            //弹窗点击遮罩层关闭
            handleClose(done) {
                this.addActivityLabel(1);
                this.ruleForm.leixing = ''//消息类型
                this.ruleForm.inputbiaoti = '';//消息标题
                this.ruleForm.textarea = ''; //活动消息
                this.ruleForm.actiContent = '';
                // this.messagePush = false;
            },

            // 获取富文本数据(活动内容)
            contentGetEditor(data) {
                if (data === '<p><br></p>') {
                    data = '';
                }
                this.ruleForm.actiContent = data;
            },

            addActivityLabel(num) {
                if (num == 0){
                    this.$refs['ruleForm'].validate((valid) => {
                        if (valid) {
                            this.$emit('xiaoxiaddActivityLabel', num, this.ruleForm)
                            this.ruleForm.leixing = ''//消息类型
                            this.ruleForm.inputbiaoti = '';//消息标题
                            this.ruleForm.textarea = ''; //活动消息
                            this.ruleForm.actiContent = '';
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                }else {
                    this.$emit('xiaoxiaddActivityLabel', num)
                    this.ruleForm.leixing = ''//消息类型
                    this.ruleForm.inputbiaoti = '';//消息标题
                    this.ruleForm.textarea = ''; //活动消息
                    this.ruleForm.actiContent = '';
                }
            },

            // tab切换
            handleSelectTab(index) {
                this.ruleForm.tabIndex = index;
                this.$refs['ruleForm'].resetFields();
            },
        }
    }
</script>

<style scoped>
</style>