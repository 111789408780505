<template>
    <div class="page" id="teamRegistration">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="query-form"
                 @keyup.enter.native="refreshList()" @submit.native.prevent>
            <el-row :gutter="20">
                <el-col :span="4">
                    <el-autocomplete
                            size="small"
                            @focus="huoquq"
                            popper-class="my-autocomplete"
                            v-model="ruleForm.name"
                            :fetch-suggestions="querySearch"
                            placeholder="请输入活动/讲座名称"
                            @select="handleSelect">
                        <i
                                slot="suffix"
                                @click="handleIconClick">
                        </i>
                        <template slot-scope="{ item }">
                            <div class="name">{{ item.value }}</div>
                        </template>
                    </el-autocomplete>
                </el-col>
                <el-col :span="4">
                    <el-input size="small" v-model="ruleForm.nickname" placeholder="请输入报名人姓名"></el-input>
                </el-col>
                <el-col :span="2">
                    <el-button size="small" icon="el-icon-search" @click="chaxun" type="primary">查询</el-button>
                </el-col>
                <el-col :span="2">
                    <el-button size="small" @click="oneClickClear" icon="el-icon-refresh-right">重置</el-button>
                </el-col>
                <el-col :span="2" :offset="12">
<!--                    <el-button v-if="hasPermission('activity:registrationManagement:export')" size="small"-->
<!--                               type="primary">导出-->
<!--                    </el-button>-->
                </el-col>
            </el-row>
            <div class="teamvaoming" style="margin: 10px 0 5px 0">名称：{{ name }}</div>
            <div class="teamvaoming" style="margin: 5px 0 10px 0">时间：<span v-if="this.data1 != ''">{{ data1 }}至{{ data2 }}</span></div>


            <el-row :gutter="20">
                <el-col :span="7">
                    <el-select style="width: 100%; margin-bottom: 10px" size="small" @change="selectinput" v-model="seessionselect"
                               placeholder="场次选择">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
<!--                <el-col :span="2">-->
<!--                    <el-button size="small" @click="queding" type="primary">确定</el-button>-->
<!--                </el-col>-->
            </el-row>
        </el-form>
        <div class="bg-white top">
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100% - 80px)"
                    @selection-change="selectionChangeHandle"
                    class="table">
                <el-table-column type="selection" width="50" fixed></el-table-column>
                <el-table-column prop="name" label="姓名" width="150">
                </el-table-column>
                <el-table-column prop="cardTypeName" label="证件类型" width="150">
                </el-table-column>
                <el-table-column prop="card" label="证件号" width="150">
                </el-table-column>
                <el-table-column prop="phone" label="联系电话" width="100">
                </el-table-column>
                <el-table-column prop="isShow" label="场次" show-overflow-tooltip>
                    <template slot-scope="scope">{{ `${scope.row.startTime} 至 ${scope.row.endTime}` }}
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="applyFile" label="申报材料" show-overflow-tooltip>
                </el-table-column>
                <el-table-column fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('activity:teamRegistration:message')" icon="el-icon-view"
                                   type="text"
                                   size="mini" @click="edit(scope.row)">发消息
                        </el-button>
                        <el-button ref="xiazai" v-if="hasPermission('activity:teamRegistration:download')" icon="el-icon-download"
                                   type="text"
                                   size="mini" @click="xiazai(scope.row)">下载
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>

        <messagePush @xiaoxiaddActivityLabel="xiaoxiaddActivityLabel" :messagePush='messagePush'
                     :xiaoxi="xiaoxi"></messagePush>
    </div>
</template>

<script>
    import Editor from '@/components/editor/editor'; // 导入富文本上传组件
    import messagePush from './messagePush' //推送消息弹窗
    export default {
        name: "teamRegistration",
        components: {Editor, messagePush},
        data() {
            return {
                name: '', //活动名称
                data1: '', //开始时间
                data2: '', //结束时间
                session: '', //场次
                seessionselect: '全场次', //选中场次
                options: [
                    {
                        value: '选项1',
                        label: '全场次'
                    },],
                dataList: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                loading: false,
                dataListSelections: [],
                ruleForm: {
                    name: '',
                    nickname: '',
                },
                rules: {},

                restaurants: [],
                timeout: null,
                search: [],
                id: '',//活动ID
                sessionId: '',//场次id

                messagePush: false,//消息推动弹窗
                xiaoxi: {},//传过去的对象
                userId: '',//用户id
                actId:'',
                applyId:'',
            }
        },
        watch: {
            'ruleForm.name': {
                handler() {
                    this.loadAll();
                },
                deep: true,
                immediate: true
            },
        },
        mounted(){
            this.$message.error('请输入报名人姓名或活动/讲座名称');
        },
        methods: {
            //一键清空
            oneClickClear(num) {
                //输入框清空
                if (num != 1) {
                    this.ruleForm.name = '';
                    this.ruleForm.nickname = '';
                }
                //清空页面数据
                this.seessionselect = '全场次';
                this.id = '';
                this.sessionId = '';
                this.name = '';
                this.data1 = '';
                this.data2 = '';
                this.search = '';
                this.dataList = [];
                this.pageNo = 1;
                this.pageSize = 10;
            },

            //实时搜索input聚焦
            huoquq() {
                this.restaurants = this.search;
            },

            //报名管理列表请求
            enrollmentManagementRequest() {
                this.$axios(this.api.activitymanagement.enrollmentManagementList, {
                    current: this.pageNo,
                    size: this.pageSize,
                })
            },

            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },

            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.queding()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.queding()
            },
            // 重置
            resetSearch() {
                this.$refs.searchForm.resetFields()
                this.queding()
            },

            // 获取富文本数据(活动内容)
            contentGetEditor(data) {
                if (data === '<p><br></p>') {
                    data = '';
                }
                this.actiContent = data;
            },

            //文件地址下载
            xiazai(row){
                const a = document.createElement('a')
                //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
                const url = row.applyFile;  // 完整的url则直接使用
                // 这里是将url转成blob地址，
                fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                    a.href = URL.createObjectURL(blob)
                    console.log(a.href)
                    a.download = row.name + '作品', // 下载文件的名字
                    // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
                    document.body.appendChild(a)
                    a.click()
                })
            },

            loadAll() {
                this.$axios(this.api.activitymanagement.allactivity, {
                    activityName: this.ruleForm.name,
                    actiServiceObj:1,
                }, 'get').then((res) => {
                    this.search = res.data;
                    this.search.map((item) => {
                        this.$set(item, 'value', item.name);
                    })
                })
            },
            handleSelect(item) {
                this.oneClickClear(1);
                this.id = item.id;
                this.$axios(this.api.activitymanagement.activityDetails + this.id, {}, 'get').then((res) => {
                    this.name = res.data.name;
                    this.data1 = res.data.startTime;
                    this.data2 = res.data.endTime;
                    this.totalQuota = res.data.maxPeople;
                    this.pepol = res.data.applyPeople;
                    this.session = res.data.activityCourse[0].activityCourseSession.length;
                    this.options = [
                        {
                            value: '选项1',
                            label: '全场次'
                        },];
                    res.data.activityCourse[0].activityCourseSession.map((item) => {
                        this.$set(item, 'label', `${item.startTime} 至 ${item.endTime}`);
                        this.$set(item, 'value', item.id);
                        this.options.push(item);
                    })
                    this.queding();
                })
            },

            //查询
            chaxun(){
                this.pageNo = 1;
                this.pageSize = 10;
                this.total = 0;
                this.queding();
            },

            //列表请求
            queding() {
                console.log(1)
                if (this.id == '' && this.ruleForm.nickname == '') {
                    this.$message.error('请输入报名人姓名或活动/讲座名称');
                    return;
                }
                this.$axios(this.api.activitymanagement.teamEnrollmentList, {
                    current: this.pageNo,
                    size: this.pageSize,
                    activityId: this.id,
                    userName: this.ruleForm.nickname,
                    sessionById: this.sessionId,
                }, 'post').then((res) => {
                    console.log(res)
                    this.dataList = res.data.records;
                    this.total = parseInt(res.data.total)
                })
            },

            selectinput(val) {
                console.log(1)
                if (this.seessionselect == '选项1') {
                    this.allowed = this.totalQuota;
                    this.participant = this.pepol;
                    this.sessionId = '';
                } else {
                    this.options.map((item) => {
                        if (item.id == val) {
                            this.allowed = item.maxPeople
                            this.participant = item.applyPeople;
                            this.sessionId = item.id;
                        }
                    })
                }
                this.queding();
            },


            //发消息
            edit(row) {
                this.actId = row.activityId;
                this.applyId = row.id;
                this.userId = row.userId;
                this.messagePush = true;
                this.xiaoxi = row;
            },

            //消息弹窗
            xiaoxiaddActivityLabel(num, object) { //1是取消，0是确定
                console.log(num, object)
                this.messagePush = false;
                let obj = {
                    actId: this.actId, //活动id
                    serverType: object.tabIndex,//服务类型
                    content: object.actiContent,//活动类容
                    applyId: this.applyId,//用户id
                    title: object.inputbiaoti,//标题
                    type: '',//类型
                    msg: object.leixing,//消息类型
                }
                if (num == 0) {
                    this.$axios(this.api.activitymanagement.singleMessageSave, obj, 'post').then((res) => {
                        console.log(res)
                        if (res.status) {
                            this.$message.success('消息推送成功')
                        }
                    })
                }
                // console.log(object)
            },

            querySearch(queryString, cb) {
                var aa = this.createFilter();
                aa.then(res=>{
                    cb(this.search);
                })
            },
            async createFilter() {
               await this.loadAll();
                return 1
            },
            handleIconClick(ev) {
                console.log(ev);
            },
        },
    }
</script>

<style scoped>

</style>